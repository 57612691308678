import { __ } from "@wordpress/i18n";
import { InnerBlocks, useBlockProps } from "@wordpress/block-editor";

export default function Edit({ attributes, ...props }) {

  console.log('context-demo',props.context)
  console.log(attributes)

  return (
    <div {...useBlockProps()}>
      <div style={{ color: "red" }}>
        a{props.context.postTitle}z
      </div>
      <InnerBlocks.Content />
    </div>
  );
}
